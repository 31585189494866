<template>
  <div style="height: 100vh" class="m-5">
    <div class="columns is-centered is-vcentered">
      <div class="column is-4-desktop is-6-tablet is-full-mobile">
        <div class="card">
          <div class="card-header" style="background: linear-gradient(89.31deg, #211895 1.38%, #6EC943 97.6%);">
            <div class="card-header-title is-flex is-justify-content-space-between is-align-items-center">
              <img width="150" :src="`/logos/${site}/logo.svg`" />
              <img width="150" :src="`/logos/${site}/bank-transfer.svg`" />
            </div>
          </div>
          <div class="card-content is-centered" v-if="tab === 0">
            <p class="mb-4 p-3 has-text-centered has-background-danger has-text-white">{{ errMsg }}</p>
            <b-button class="mr-3" type="is-danger" size="is-small" @click="cancel">Kapat</b-button>
          </div>
          <section class="section is-main-section" v-if="tab === 1">
              <form @submit.prevent="save">
                <b-field label="Ad & Soyad" message="Banka hesabınızdaki ad soyad ile aynı olmalıdır">
                  <b-input v-model="form.name" size="is-small" disabled />
                </b-field>
                <b-field label="Yatırım tutarı">
                  <b-input v-model="form.amount" size="is-small" disabled />
                </b-field>
                <div class="is-flex is-full is-justify-content-end">
                  <b-button class="mr-3" type="is-danger" size="is-medium" @click="cancel">Vazgeç</b-button>
                  <b-button :disabled="!isValid" type="is-primary" size="is-medium" expanded native-type="submit">Devam
                    et</b-button>
                </div>
              </form>
          </section>
          <section class="section is-main-section" v-if="tab === 2">
              <form @submit.prevent="complete">
                <div>
                  <ul>
                    <li class="bankAccountLi" style="justify-content: center; align-items: center; display: flex" v-for="account in accountList" :key="account.id" @click="() => {bankAccount = account.id; complete()}">
                        <span><img width="200" :src="account.logo" /></span>
                    </li>
                  </ul>
                </div>
                <!--
                <div class="is-flex is-full is-justify-content-end">
                  <b-button class="mr-3" type="is-danger" size="is-small" @click="cancel">Kapat</b-button>
                  <b-button :disabled="!bankAccount" type="is-success" size="is-small" native-type="submit">İşlemi
                    tamamla</b-button>
                </div>
                -->
              </form>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import {
  IntegrationService,
  TransactionService,
  UserService,
} from "@/services";

export default {
  name: "BankTransferIframe",
  components: { CardComponent },
  data() {
    return {
      loading: false,
      error: false,
      errMsg: "",
      tab: 0,
      form: {
        appKey: this.$route.query.appKey,
        appSecret: this.$route.query.appSecret,
        username: this.$route.query.username,
        name: this.$route.query.name,
        amount: this.$route.query.amount,
        userId: this.$route.query.userId,
        jwt: this.$route.query.jwt,
        processId: this.$route.query.processId,
        failRedirectUrl: this.$route.query.failRedirectUrl,
        successRedirectUrl: this.$route.query.successRedirectUrl
      },
      bankAccount: null,
      accountList: [],
      transactionId: null
    };
  },
  computed: {
    isValid() {
      return (
        this.form.appKey &&
        this.form.appSecret &&
        this.form.userId &&
        this.form.username &&
        this.form.name &&
        this.form.amount >= 50
      );
    },
  },
  mounted() {
    const html = document.getElementsByTagName("html")[0];
    html.classList = [];
    this.init();
  },
  methods: {
    init() {
      UserService.providerCheck(this.form)
        .then(async () => {
          this.tab = 1
          this.save()
        })
        .catch(() => (this.error = true));
    },
    async save() {
      if (this.form.amount < 50) {
        return false
      }
      const request = {
        username: this.form.username,
        name: this.form.name,
        amount: this.form.amount,
        userId: this.form.userId,
        processId: this.form.processId,
        jwt: this.form.jwt,
        failRedirectUrl: this.form.failRedirectUrl,
        successRedirectUrl: this.form.successRedirectUrl
      };
      const { data } = await TransactionService.hashCreate(request, this.form.appSecret);
      IntegrationService.bankTransferDeposit(request, data, this.form.appKey)
        .then((res) => {
          this.accountList = res.data.bankList;
          this.transactionId = res.data.transactionId;
          this.tab = 2
        })
        .catch((err) => {
          this.tab = 0;
          this.errMsg = err.message
        });
    },
    async complete() {
      const request = {
        transactionId: this.transactionId,
        bankAccountId: this.bankAccount
      }
      const { data } = await TransactionService.hashCreate(request, this.form.appSecret);
      IntegrationService.bankTransferNotify(request, data, this.form.appKey)
        .then((res) => this.$router.push({ name: 'bankTransferPay', params: { id: this.transactionId } }))
        .catch((err) => {
          this.tab = 0;
          this.errMsg = err.message
        });
    },
    cancel() {
      if (this.tab !== 0 && this.transactionId) {
        this.loading = true
        TransactionService.cancel({ transactionId: this.transactionId })
          .then(res => {
            this.$buefy.toast.open({
              message: res.data.message,
              position: 'is-top-right',
              type: 'is-success',
              duration: 2000
            })
            setTimeout(() => {
              window.location.href = res.data.redirectUrl
            }, 2000)
          })
          .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-bottom', type: 'is-info' }))
          .finally(() => this.loading = false)
      } else {
        window.location.href = this.$route.query.failRedirectUrl
      }
    }
  },
};
</script>
<style>
.bankAccountLi{
  padding: 10px;
  border: 1px solid #9c6cfe;
  border-radius: 10px;
  background-color: transparent;
  margin-bottom: 1rem;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.bankAccountLi:hover{
  opacity: 0.65;
  cursor:pointer;
  border: 1px solid #352cab;
  background-color: #9c6cfe;
  transition: background-color .75s
}

.bankAccountLi:active{
  transform: scale(0.95);
}
</style>